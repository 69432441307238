$primary: #3D71B5;
$secondary: #85CDFD;
$success: #54B435;
$danger: #CF0A0A;
$warning: #F0FF42;
$info: #DEFCF9;
$secondary-tint: #F5F8FA;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
);

$font-oswald: 'Oswald', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-kavivanar: 'Kavivanar', sans-serif;
$font-martel: 'Martel Sans', sans-serif;